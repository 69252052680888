<template>
	<el-container style="height: 6230px;">
		<div class="block">
			<el-carousel height="800px" loop arrow="never">
				<el-carousel-item>
					<img src="../../../assets/image/startbackgroud.png" alt="" style="width: 1920px; height: 800px;">
					<img src="../../../assets/image/title1.png" class="title1">
					<div class="text1">当老板遇到以下尴尬，怎么拯救他们？</div>
					<div class="text text2">
						<img src="../../../assets/image/false.png"> 
						<div style="margin-left: 9px;">看不懂专业的财务报表？</div>
					</div>
					<div class="text text3">
						<img src="../../../assets/image/false.png"> 
						<div style="margin-left: 9px;">传统的“三大财报”太笼统，远远不够用？</div>
					</div>
					<div class="text text4">
						<img src="../../../assets/image/false.png"> 
						<div style="margin-left: 9px;">会计加班加点赶报表，但还是太慢了？</div>
					</div>
					<div class="text text5">
						<img src="../../../assets/image/false.png"> 
						<div style="margin-left: 9px;">想要的管理报表，会计不会做？</div>
					</div>
					<div class="text text6">
						<img src="../../../assets/image/false.png"> 
						<div style="margin-left: 9px;">决策没有数据支撑，心慌？</div>
					</div>
					<img src="../../../assets/image/title2.png" class="title2">
					<div class="text7">壹本账，让老板轻松管理企业。</div>
					<div class="text text8">
						<img src="../../../assets/image/true.png"> 
						<div style="margin-left: 9px;">没有专业术语，每个老板都能看得懂账！</div>
					</div>
					<div class="text text9">
						<img src="../../../assets/image/true.png"> 
						<div style="margin-left: 9px;">实时生成管理报表，随时随地看账！</div>
					</div>
					<div class="text text10">
						<img src="../../../assets/image/true.png"> 
						<div style="margin-left: 9px;">38张管理报表，老板想要的管理报表全都有！</div>
					</div>
					<div class="text text11">
						<img src="../../../assets/image/true.png"> 
						<div style="margin-left: 9px;">小白就能做账，门槛低！</div>
					</div>
				</el-carousel-item>
				<el-carousel-item>
					<img src="../../../assets/image/maohao.png" alt="" style="position: absolute; top: 100px; left: 210px;">
					<img src="../../../assets/image/startbackgroud2.png" alt="" style="width: 1920px; height: 800px;">
					<div class="text13">数据是一切管理的基础。</br>
					企业的所有决策，都应当以数据为基准，</br>
					而不是靠拍脑袋。</div>
					<img src="../../../assets/image/person.png" alt="" class="person">
					<div class="text14">孙同献</div>
					<div class="text15">壹本账创始人</br>
					373业务模型提出者</br>
					7大管理维度理论首创者</div>
					<div class="text16">90%中小企业的内账都是“糊涂账”， </br>
					 老板还停留在经验管理阶段，数字化管理意识还没有觉醒。</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="middletitle">
			<div class="text17">20</div>
			<div class="text18">年</div>
			<div class="text19">管理经验沉淀</div>
			<div class="line line1"></div>
			<div class="text17" style="left: 485px;">60</div>
			<div class="text18" style="left: 567px;">个</div>
			<div class="text19" style="left: 491px;">覆盖城市</div>
			<div class="line line1" style="left: 700px;"></div>
			<div class="text17" style="left: 805px;">200</div>
			<div class="text18" style="left: 929px; font-size: 36px;">+</div>
			<div class="text19" style="left: 806px;">合作伙伴</div>
			<div class="line line1" style="left: 1053px;"></div>
			<div class="text17" style="left: 1135px;">10000</div>
			<div class="text18" style="left: 1344px; font-size: 36px;">+</div>
			<div class="text19" style="left: 1201px;">终端用户</div>
		</div>
		
		<div class="block2">
			<div class="text20">认识壹本账</div>
			<div class="body body1">
				<div class="round">
					<div class="text21">1</div>
					<div style="font-size: 30px;color: #fff;display: flex;align-items: center;justify-content: center;">本账</div>
				</div>
				<div class="text22">
					系统以企业经营活动中的业务单据为起点，实时生成满足老板决策需求的管理报表，同时自动生成专业的记账凭证和财务报表，智能化实现“<b>两账合一</b>”。
					</br>两账合一，就用壹本账。
				</div>
			</div>
			<div class="body body2">
				<div class="round">
					<div class="text21">2</div>
					<div style="font-size: 30px;color: #fff;display: flex;align-items: center;justify-content: center;">张单</div>
				</div>
				<div class="text22">
					系统只需要录入2种业务单据：</br>
					<b>收付款单</b>和<b>出入库单</b>，不需要录入
					专业的记账凭证。</br>
					——哪怕是不懂财务的小白，也能快速上手，门槛低。</br>用壹本账，人人都会做账。
				</div>
			</div>
			<div class="body body3">
				<div class="round">
					<div class="text21">38</div>
					<div style="font-size: 30px;color: #fff;display: flex;align-items: center;justify-content: center;">张表</div>
				</div>
				<div class="text22" style="margin-left: 40px; margin-right: 40px;">
					<div style="display: flex; align-items: center;">
						<img src="../../../assets/image/true2.png" style="width: 29px; height: 26px;">
						<div style="margin-left: 9px;">实时生成，老板随时能看。</div>
					</div>
					<div style="display: flex; align-items: center;">
						<img src="../../../assets/image/true2.png" style="width: 29px; height: 26px;">
						<div style="margin-left: 9px;">没有专业术语，老板一看就懂。</div>
					</div>
					<div style="display: flex; align-items: flex-start; justify-content: center;">
						<img src="../../../assets/image/true2.png" style="width: 29px; height: 26px; padding-top: 5px;">
						<div style="margin-left: 9px;">多维度组合分析；阿米巴核算；多项目核算……老板想要的数据全都有。</div>
					</div>
				</div>
				
				<div class="text22" style="margin: 0 35px;">
					<div style="margin-left: 9px;">壹本账，每个老板都需要的管理账。</div>
				</div>
			</div>
		</div>
		
		<div class="block3">
			<div>
				<div class="text23">
					公司整体经营状况<b style="color: #FF0000;">一手掌控</b>
				</div>
				<div class="text24">
					《经营盈亏表》（相当于“利润表”）</br>《现金收支表》</br>《现金流量表》
				</div>
				<div class="text25">
					<div style="display: flex; align-items: center;">
						<img src="../../../assets/image/true2.png" style="width: 29px; height: 26px;">
						<div style="margin-left: 9px;">报表通俗易懂，没有专业术语，一看就懂。</div>
					</div>
					<div style="display: flex; align-items: center;">
						<img src="../../../assets/image/true2.png" style="width: 29px; height: 26px;">
						<div style="margin-left: 9px;">电脑端＋手机端（微信小程序），数据实时同步。</div>
					</div>
				</div>
			</div>
			<div style="width: 962px; height: 525px !important; position: absolute; left: 839px; top: 128px;">
				<el-carousel height="525px" loop arrow="never">
					<el-carousel-item>
						<img src="../../../assets/image/form1.png" alt="">
					</el-carousel-item>
					<el-carousel-item>
						<img src="../../../assets/image/form2.png" alt="">
					</el-carousel-item>
					<el-carousel-item>
						<img src="../../../assets/image/form3.png" alt="">
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		
		<div class="block4">
			<div style="width: 788px; height: 555px !important; position: absolute; left: 210px; top: 100px;">
				<el-carousel height="555px" loop arrow="never">
					<el-carousel-item>
						<img src="../../../assets/image/form4.png" alt="">
					</el-carousel-item>
					<el-carousel-item>
						<img src="../../../assets/image/form5.png" alt="">
					</el-carousel-item>
					<el-carousel-item>
						<img src="../../../assets/image/form6.png" alt="">
					</el-carousel-item>
					<el-carousel-item>
						<img src="../../../assets/image/form7.png" alt="">
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="text26">
				<b style="color: #FF0000;">一站式</b>查询
			</div>
			<div class="text27">
				<b style="color: #FF0000;">11项</b>常用查询：想查什么，就查什么！
			</div>
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1060px; top: 236px;">
				<img src="../../../assets/image/bankcash.png" style="width: 30px; height: 30px;">
				<div style="margin-left: 9px;">现金及银行存款</div>
			</div>
			
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1512px; top: 236px;">
				<img src="../../../assets/image/amiba.png" style="width: 30px; height: 30px;">
				<div style="margin-left: 9px;">阿米巴管理</div>
			</div>
			
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1060px; top: 276px;">
				<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">应收款查询</div>
			</div>
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1060px; top: 316px;">
				<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">应收款账龄分析</div>
			</div>
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1060px; top: 356px;">
				<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">应付款查询</div>
			</div>
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1060px; top: 396px;">
				<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">收入查询</div>
			</div>
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1060px; top: 436px;">
				<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">支出查询</div>
			</div>
			
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1280px; top: 276px;">
				<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">存货查询</div>
			</div>
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1280px; top: 316px;">
				<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">税费查询</div>
			</div>
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1280px; top: 356px;">
				<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">其他查询</div>
			</div>
			
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1510px; top: 276px;">
				<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">业务员绩效考核</div>
			</div>
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1510px; top: 316px;">
				<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">客户贡献度分析</div>
			</div>
			<div class="text28" style="display: flex; align-items: center; position: absolute; left: 1510px; top: 356px;">
				<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">部门绩效考核</div>
			</div>
			
			<div class="text29" style="display: flex; align-items: center; position: absolute; left: 1060px; top: 503px;">
				<div style="width: 10px; height: 10px; background: #000000; border-radius: 5px;"></div>
				<div style="margin-left: 9px;">查询条件，可以自由选取和组合。</div>
			</div>
			<div class="text29" style="display: flex; align-items: center; position: absolute; left: 1060px; top: 550px;">
				<div style="width: 10px; height: 10px; background: #000000; border-radius: 5px;"></div>
				<div style="margin-left: 9px;">明细数据＋汇总数据，立马就能查到。</div>
			</div>
		</div>
		
		<div class="block5">
			<div>
				<div class="text30">
					<b style="color: #FF0000;">多维度</b>数据分析
				</div>
				<div class="text31">
					可以对数据进行自由组合和多维度分析，让数据分析更简单、更直观！
				</div>
				<div class="text32" style="display: flex; align-items: center; position: absolute; left: 210px; top: 255px;">
					<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">费用对比分析</div>
				</div>
				<div class="text32" style="display: flex; align-items: center; position: absolute; left: 210px; top: 295px;">
					<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">劳务收入分析</div>
				</div>
				<div class="text32" style="display: flex; align-items: center; position: absolute; left: 210px; top: 335px;">
					<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">销售额分析</div>
				</div>
				<div class="text32" style="display: flex; align-items: center; position: absolute; left: 210px; top: 375px;">
					<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">销售额排名</div>
				</div>
				
				<div class="text32" style="display: flex; align-items: center; position: absolute; left: 432px; top: 255px;">
					<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">销售价格分析</div>
				</div>
				<div class="text32" style="display: flex; align-items: center; position: absolute; left: 432px; top: 295px;">
					<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">采购价格分析</div>
				</div>
				<div class="text32" style="display: flex; align-items: center; position: absolute; left: 432px; top: 335px;">
					<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">销售日报表</div>
				</div>
				<div class="text32" style="display: flex; align-items: center; position: absolute; left: 432px; top: 375px;">
					<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">生产日报表</div>
				</div>
				
				<div class="text33">
					可以根据不同期间、部门、员工、客户、供应商、商品、劳务、车间等维度，进行智能分析。
				</div>
			</div>
			<div style="width: 788px; height: 555px !important; position: absolute; left: 960px; top: 100px;">
				<el-carousel height="555px" loop arrow="never">
					<el-carousel-item>
						<img src="../../../assets/image/form8.png" alt="">
					</el-carousel-item>
					<el-carousel-item>
						<img src="../../../assets/image/form9.png" alt="">
					</el-carousel-item>
					<el-carousel-item>
						<img src="../../../assets/image/form10.png" alt="">
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		
		<div class="block6">
			<div style="width: 788px; height: 555px !important; position: absolute; left: 210px; top: 103px;">
				<img src="../../../assets/image/form11.png" alt="">
			</div>
			<div class="text26">
				<b style="color: #FF0000;">真实盈亏</b>管理
			</div>
			<div class="text34">
				公司明明有营收，为什么没有利润？</br>
				为什么公司收入增长很快，但是却不赚钱？</br>
				每天忙里忙外的，公司到底赚钱吗？
			</div>
			<div class="text35">
				<div style="display: flex; align-items: flex-start; justify-content: center;">
					<img src="../../../assets/image/false2.png" style="width: 26px; height: 26px; padding-top: 5px;">
					<div style="margin-left: 9px;">进销存系统，一般只能核算库存和往来账，没有归集费用、报销等数据，
不能真实反映公司的盈亏状况。</div>
				</div>
				<div style="display: flex; align-items: flex-start; justify-content: center; top: 405;">
					<img src="../../../assets/image/true4.png" style="width: 29px; height: 26px; padding-top: 5px;">
					<div style="margin-left: 9px;">壹本账系统，覆盖公司经营的全链路数据，可以完整反映公司的经营状况，
反映真实的盈亏。</div>
				</div>
			</div>
			<div class="text36">
				*可以自行选择“<b>收付实现制</b>”或“<b>权责发生制</b>”（绝大多数软件无法支持）。
			</div>
		</div>
		
		<div class="block7">
			<div>
				<div class="text37">
					<b style="color: #FF0000;">阿米巴</b>核算
				</div>
				<div class="text38">
					既可以实现整个公司的<b>统一核算</b>，也可以实现每个小组织的<b>独立核算</b>。
				</div>
				
				<div class="text39" style="position: absolute; left: 210px; top: 296px;">典型应用场景:</div>
				<div class="text39" style="display: flex; align-items: center; position: absolute; left: 210px; top: 342px;">
					<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">建筑企业的多项目核算</div>
				</div>
				<div class="text39" style="display: flex; align-items: center; position: absolute; left: 210px; top: 382px;">
					<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">连锁企业的多门店核算</div>
				</div>
				<div class="text39" style="display: flex; align-items: center; position: absolute; left: 210px; top: 422px;">
					<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">教培机构的多培训项目核算</div>
				</div>
				<div class="text39" style="display: flex; align-items: center; position: absolute; left: 210px; top: 462px;">
					<img src="../../../assets/image/true3.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">运输企业的多线路核算</div>
				</div>
				
				<div class="text39" style="position: absolute; left: 210px; top: 507px;">. . . . . .</div>
			</div>
			<div style="width: 788px; height: 555px !important; position: absolute; left: 960px; top: 108px;">
				<img src="../../../assets/image/form8.png" alt="">
			</div>
		</div>
		
		<div class="block8">
			<div style="width: 788px; height: 555px !important; position: absolute; left: 210px; top: 110px;">
				<img src="../../../assets/image/form12.png" alt="">
			</div>
			<div class="text26">
				<b style="color: #FF0000;">首创</b>JIT成本核算 
			</div>
			<div class="text40">
				不需要耗时费力计算单个产品的成本，就能轻松、快速核算企业的经营盈亏！
				</br>打破传统成本核算难度大、效率低的弊端。
				</br>适用于：生产制造业、加工业（如蛋糕店）等。
			</div>
			<div class="text41">
				传统成本核算>>>
			</div>
			<div class="box" style="top: 390px; left: 1040px;">
				原材料
			</div>
			<img src="../../../assets/image/arrow.png" alt="" style="width: 30px; height: 32px; position: absolute; top: 405px; left: 1183px;">
			<div class="box" style="top: 390px; left: 1220px;">
				生产成本
			</div>
			<img src="../../../assets/image/arrow.png" alt="" style="width: 30px; height: 32px; position: absolute; top: 405px; left: 1362px;">
			<div class="box" style="top: 390px; left: 1400px;">
				产成品成本
			</div>
			<img src="../../../assets/image/arrow.png" alt="" style="width: 30px; height: 32px; position: absolute; top: 405px; left: 1541px;">
			<div class="box" style="top: 390px; left: 1580px;">
				营业成本
			</div>
			<div class="text41" style="top: 474px;">
				JIT成本核算>>>
			</div>
			<div class="box" style="top: 511px; left: 1040px;">
				原材料
			</div>
			<img src="../../../assets/image/arrow.png" alt="" style="width: 30px; height: 32px; position: absolute; top: 526px; left: 1183px;">
			<div class="box" style="top: 511px; left: 1220px;">
				营业成本
			</div>
		</div>
		<div class="block9">
			<div class="text42">开始使用壹本账</div>
			<div class="text43">做老板　就要有壹本账</div>
			<div class="button" @mouseenter="buttonEnter()" @mouseleave="buttonLeave()">{{buttontext}}</div>
		</div>
	</el-container>
</template>

<script>
  export default {
    name: "start",
		data() {
			return {
				buttontext: "免费咨询"
			}
		},
		methods:{
			buttonEnter() {
				this.buttontext = "合作专线：13807323918（孙老师）";
			},
			buttonLeave() {
				this.buttontext = "免费咨询";
			},
		}
  }
</script>

<style lang="less" scoped>
	.el-container {
		width: 1920px;
		
		.block {
			width: 1920px;
			height: 800px;
			
			.title1 {
				width: 501px;
				height: 72px;
				position: absolute;
				left: 261px;
				top: 137px;
			}
			
			.title2 {
				width: 385px;
				height: 143px;
				position: absolute;
				left: 1254px;
				top: 205px;
			}
			
			.person {
				width: 521px;
				height: 745px;
				position: absolute;
				left: 1197px;
				top: 55px;
			}
			
			.text1 {
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #FFFFFF;
				position: absolute;
				left: 265px;
				top: 244px;
			}
			
			.text7 {
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #491800;
				position: absolute;
				left: 1254px;
				top: 375px;
			}
			
			.text {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 48px;
				display: flex;
				align-items: center;
			}
			
			.text2 {
				position: absolute;
				left: 265px;
				top: 288px;
			}
			.text3 {
				position: absolute;
				left: 265px;
				top: 321px;
			}
			.text4 {
				position: absolute;
				left: 265px;
				top: 354px;
			}
			.text5 {
				position: absolute;
				left: 265px;
				top: 387px;
			}
			.text6 {
				position: absolute;
				left: 265px;
				top: 420px;
			}
			.text8 {
				position: absolute;
				left:1256px;
				top: 416px;
				color: #491900;
			}
			.text9 {
				position: absolute;
				left: 1256px;
				top: 449px;
				color: #491900;
			}
			.text10 {
				position: absolute;
				left: 1256px;
				top: 482px;
				color: #491900;
			}
			.text11 {
				position: absolute;
				left: 1256px;
				top: 515px;
				color: #491900;
			}
			.text12 {
				width: 53px;
				height: 42px;
				font-size: 156px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: 400;
				color: #FFFFFF;
				position: absolute;
				top: 100px;
				left: 210px;
			}
			.text13 {
				font-size: 54px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 72px;
				position: absolute;
				top: 145px;
				left: 276px;
			}
			.text14 {
				font-size: 32px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FFFFFF;
				position: absolute;
				top: 356px;
				right: 719px;
			}
			.text15 {
				text-align: right;
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 30px;
				position: absolute;
				top: 410px;
				right: 719px;
			}
			.text16 {
				font-size: 30px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 40px;
				position: absolute;
				top: 529px;
				left: 275px;
			}
			
			::v-deep .el-carousel__button {
				width: 18px;
				height: 18px;
				background-color: #FFFFFF;
				border-radius: 50%;
				margin-right: 21px;
				margin-bottom: 120px !important;
			}
		}
		
		.middletitle {
			position: absolute;
			top: 801px;
			left: 210px;
			width: 1500px;
			height: 206px;
			background: #FFFFFF;
			box-shadow: 12px 10px 21px 0px rgba(63,63,63,0.26);
			border-radius: 20px;
			z-index: 1000;
			
			.text17 {
				font-size: 72px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #FF0000;
				line-height: 72px;
				position: absolute;
				bottom: 93px;
				left: 166px;
			}
			
			.text18 {
				font-size: 30px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #585858;
				position: absolute;
				bottom: 93px;
				left: 250px;
			}
			
			.text19 {
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #585858;
				position: absolute;
				bottom: 50px;
				left: 139px;
			}
			
			.line {
				width: 1px;
				height: 123px;
				background: #585858;
			}
			
			.line1 {
				position: absolute;
				bottom: 42px;
				left: 376px;
			}
		}
		
		.block2 {
			width: 1920px;
			height: 1068px;
			background: #F82905;
			position: absolute;
			top: 900px;
			
			.text20 {
				font-size: 55px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #FFFFFF;
				position: absolute;
				top: 208px;
				left: 827px;
			}
			
			.body {
				width: 400px;
				height: 590px;
				background: #FFF;
				border-radius: 20px;
				
				.round {
					position: absolute; 
					top: 76px;
					left:123px;
					width: 155px;
					height: 155px;
					border-radius: 155px;
					background-color: #E60012;
				}
				
				.text21 {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 15px;
					font-size: 72px;
					font-family: AlibabaPuHuiTi_2_75_SemiBold;
					font-weight: 800;
					color: #FFFFFF;
					line-height: 80px;
				}
				
				.text22 {
					font-size: 20px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #000000;
					line-height: 36px;
					margin: 281px 50px 0 50px;
				}
			}
			
			.body:hover  {
				background: #FFF9EF;
				box-shadow: 12px 10px 21px 0px rgba(63,63,63,0.26);
			}
			
			.body1 {
				position: absolute;
				left: 210px;
				top: 333px;
			}
			
			.body2 {
				position: absolute;
				left: 760px;
				top: 333px;
			}
			
			.body3 {
				position: absolute;
				left: 1310px;
				top: 333px;
			}
		}
		
		.block3 {
			width: 1920px;
			height: 676px;
			background: #F0F3FA;
			position: absolute;
			top: 1968px;
			
			.text23 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				position: absolute;
				top: 166px;
				left: 210px;
			}
			
			.text24 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #5C5C5C;
				line-height: 36px;
				position: absolute;
				top: 239px;
				left: 210px;
			}
			
			.text25 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 48px;
				position: absolute;
				top: 430px;
				left: 210px;
			}
			
			::v-deep .el-carousel__button {
				width: 18px;
				height: 18px;
				background-color: #2B2E4F;
				border-radius: 50%;
				margin-right: 21px;
			}
		}
		
		.block4 {
			width: 1920px;
			height: 676px;
			background: #ffffff;
			position: absolute;
			top: 2644px;
			
			.text26 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				position: absolute;
				left: 1040px;
				top: 117px;
			}
			
			.text27 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 36px;
				position: absolute;
				left: 1040px;
				top: 186px;
			}
			
			.text28 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #5C5C5C;
				line-height: 48px;
			}
			
			.text29 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 48px;
			}
			
			::v-deep .el-carousel__button {
				width: 18px;
				height: 18px;
				background-color: #2B2E4F;
				border-radius: 50%;
				margin-right: 21px;
			}
		}
		
		.block5 {
			width: 1920px;
			height: 676px;
			background: #F0F3FA;
			position: absolute;
			top: 3316px;
			
			.text30 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				position: absolute;
				top: 130px;
				left: 210px;
			}
			
			.text31 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 36px;
				position: absolute;
				top: 200px;
				left: 210px;
			}
			
			.text32 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #5C5C5C;
				line-height: 48px;
			}
			
			.text33 {
				width: 660px;
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 36px;
				position: absolute;
				top: 475px;
				left: 210px;
			}
			
			::v-deep .el-carousel__button {
				width: 18px;
				height: 18px;
				background-color: #2B2E4F;
				border-radius: 50%;
				margin-right: 21px;
			}
		}
		
		.block6 {
			width: 1920px;
			height: 676px;
			background: #ffffff;
			position: absolute;
			top: 3989px;
			
			.text26 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				position: absolute;
				left: 1040px;
				top: 117px;
			}
			
			.text34 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #5C5C5C;
				line-height: 36px;
				position: absolute;
				left: 1040px;
				top: 205px;
			}
			
			.text35 {
				width: 615px;
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 36px;
				position: absolute;
				left: 1045px;
				top: 323px;
			}
			
			.text36 {
				font-size: 18px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FF0000;
				line-height: 36px;
				position: absolute;
				left: 1045px;
				top: 535px;
			}
		}
		
		.block7 {
			width: 1920px;
			height: 676px;
			background: #F0F3FA;
			position: absolute;
			top: 4662px;
			
			.text37 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				position: absolute;
				top: 152px;
				left: 210px;
			}
			
			.text38 {
				width: 620px;
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 36px;
				position: absolute;
				top: 222px;
				left: 210px;
			}
			
			.text39 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #5C5C5C;
				line-height: 36px;
			}
			
			.text33 {
				width: 660px;
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 36px;
				position: absolute;
				top: 475px;
				left: 210px;
			}
		}
	
		.block8 {
			width: 1920px;
			height: 676px;
			background: #ffffff;
			position: absolute;
			top: 5334px;
			
			.text26 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				position: absolute;
				left: 1040px;
				top: 117px;
			}
			
			.text40 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #010101;
				line-height: 36px;
				position: absolute;
				left: 1040px;
				top: 210px;
			}
			
			.text41 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #010101;
				line-height: 36px;
				position: absolute;
				left: 1045px;
				top: 355px;
			}
			
			.box {
				width: 134px; 
				height: 63px; 
				border: 1px solid #868686; 
				border-radius: 10px; 
				position: absolute; 
				display: flex; 
				align-items: center; 
				justify-content: center;
			}
		}
		
		.block9 {
			width: 1920px;
			height: 320px;
			position: absolute;
			top: 6010px;
			background-image: url(../../../assets/image/beginybz.png);
			
			.text42 {
				font-size: 50px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: 600;
				color: #000000;
				position: absolute;
				left: 793px;
				top: 113px;
			}
			
			.text43 {
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #000000;
				position: absolute;
				left: 846px;
				top: 180px;
			}
			
			.button {
				width: 212px;
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #FF0000;
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FFFFFF;
				border-radius: 30px;
				position: absolute;
				left: 854px;
				top: 227px;
			}
			
			.button:hover {
				width: 450px;
				height: 60px;
				background: linear-gradient(0deg, #D50000 0%, #FF5400 100%);
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FFFFFF;
				border-radius: 30px;
				position: absolute;
				left: 735px;
				top: 227px;
			}
		}
	}
</style>